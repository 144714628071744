import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@formio/react';
import './assets/bootstrap.min.css';

const formSchema = {
  "_id": "6700b0058053a41f2b52a243",
  "title": "Formulario",
  "name": "form6",
  "path": "formulario",
  "type": "form",
  "display": "form",
  "components": [
    {
      "label": "Nombre",
      "key": "nombre",
      "type": "textfield",
      "input": true,
    },
    {
      "label": "Email",
      "key": "email",
      "type": "textfield",
      "input": true,
    },
    {
      "label": "Edad",
      "key": "edad",
      "type": "textfield",
      "input": true,
    },
    {
      "label": "Telefono",
      "key": "telefono",
      "type": "textfield",
      "input": true,
    },
    {
      "label": "Direccion",
      "key": "direccion",
      "type": "textfield",
      "input": true,
    }
  ]
};

const App = () => {
  const [formData, setFormData] = useState(null); // Almacenar los datos del formulario
  const [photo, setPhoto] = useState(null); // Estado para almacenar la foto capturada
  const [location, setLocation] = useState({ lat: '', lng: '', city: '', country: '' });
  const [facingMode, setFacingMode] = useState('user'); // Estado para la cámara (frontal o trasera)
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Estado para manejar conexión a Internet
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Función para iniciar la cámara con el modo seleccionado
  const startCamera = () => {
    const constraints = {
      video: {
        facingMode: facingMode, // Cambiar entre 'user' (frontal) y 'environment' (trasera)
      },
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error('Error al acceder a la cámara:', err);
      });
  };

  // Función para capturar la foto desde el video y convertirla en Blob
  const capturePhoto = () => {
    if (!canvasRef.current) {
      console.error('Canvas no encontrado.');
      return;
    }

    const context = canvasRef.current.getContext('2d');
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    // Convertir la imagen en Blob
    canvasRef.current.toBlob((blob) => {
      setPhoto(blob); // Guardar la imagen como Blob
    }, 'image/png');
  };

  // Función para obtener la ubicación del usuario
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation((prevState) => ({ ...prevState, lat: latitude, lng: longitude }));
        getCityAndCountry(latitude, longitude);
      });
    } else {
      alert('La geolocalización no está disponible en este navegador.');
    }
  };

  // Función para obtener la ciudad y el país a partir de las coordenadas
  const getCityAndCountry = async (latitude, longitude) => {
    const apiKey = 'AIzaSyD_pPIPAQZYmiHwrXRjEqEF1_VYvVj_6To'; // Coloca tu API key aquí
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        const addressComponents = data.results[0].address_components;
        const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
        const country = addressComponents.find((comp) => comp.types.includes('country'))?.long_name || '';
        setLocation((prevState) => ({ ...prevState, city, country }));
      } else {
        console.error('Error en la geolocalización inversa:', data.status);
      }
    } catch (error) {
      console.error('Error al obtener la ciudad y el país:', error);
    }
  };

  // Función para alternar entre la cámara frontal y trasera
  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  // Función para manejar el envío del formulario junto con la imagen y la geolocalización
  const handleSubmit = () => {
    if (!formData) {
      console.error('Formulario no está listo.');
      return;
    }

    if (!location.lat || !location.lng) {
      alert('Por favor, espera a que la geolocalización se cargue.');
      return;
    }

    // Crear objeto FormData para enviar los datos y la imagen
    const formDataToSend = new FormData();
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('edad', formData.edad);
    formDataToSend.append('telefono', formData.telefono);
    formDataToSend.append('direccion', formData.direccion);
    formDataToSend.append('latitud', location.lat);
    formDataToSend.append('longitud', location.lng);
    formDataToSend.append('ciudad', location.city);
    formDataToSend.append('pais', location.country);
    if (photo) {
      formDataToSend.append('photo', photo); // Añadir la imagen como Blob
    }

    if (isOnline) {
      // Si está en línea, enviar los datos directamente
      sendData(formDataToSend);
    } else {
      // Si no hay conexión, guardar los datos en localStorage
      localStorage.setItem('formData', JSON.stringify(Object.fromEntries(formDataToSend)));
      alert('Datos guardados en localStorage debido a falta de conexión.');
    }
  };

  // Función para enviar los datos al servidor
  const sendData = (formDataToSend) => {
    fetch('https://my-worker.ipuga2201.workers.dev/', {
      method: 'POST',
      body: formDataToSend, // Enviar como FormData
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Datos enviados al servidor:', data);
        // Limpiar los datos después del envío
        setFormData({});
        setPhoto(null);
        setLocation({ lat: '', lng: '', city: '', country: '' });
        alert('Datos enviados.');
      })
      .catch((error) => {
        console.error('Error al enviar los datos al servidor:', error);
      });
  };

   // Listener para enviar los datos automáticamente cuando se restablezca la conexión
  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);

      // Si volvemos a estar en línea y hay datos en localStorage, enviarlos
      if (navigator.onLine && localStorage.getItem('formData')) {
        const savedData = JSON.parse(localStorage.getItem('formData'));
        const formDataToSend = new FormData();
        Object.keys(savedData).forEach((key) => {
          formDataToSend.append(key, savedData[key]);
        });

        sendData(formDataToSend); // Enviar los datos
        localStorage.removeItem('formData'); // Limpiar localStorage
        alert('Datos enviados al restaurar la conexión.');
      }
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);
  
  useEffect(() => {
    getLocation(); // Obtener la ubicación cuando se monta el componente
  }, [facingMode]);

  return (
    <div className="container mt-5">
      <h1>{formSchema.title || 'Formulario'}</h1>

      <div className={`alert ${isOnline ? 'alert-success' : 'alert-danger'}`} role="alert">
        {isOnline ? 'Conectado a Internet' : 'Desconectado de Internet'}
      </div>

      {/* Renderiza el formulario basado en el esquema de Form.io */}
      <Form
        form={formSchema}
        onChange={(submission) => setFormData(submission.data)} // Captura los datos del formulario al cambiar
      />

      {/* Botones para manejo de la cámara */}
      <div className="text-center mt-4">
        <button type="button" className="btn btn-secondary" onClick={startCamera}>
          Iniciar Cámara
        </button>
      </div>

      <div className="text-center mt-2">
        <button type="button" className="btn btn-info" onClick={toggleCamera}>
          Cambiar Cámara ({facingMode === 'user' ? 'Frontal' : 'Trasera'})
        </button>
      </div>

      <div className="text-center mt-4">
        <video ref={videoRef} style={{ width: '100%', maxHeight: '300px' }}></video>
      </div>

      <div className="text-center mt-3">
        <button type="button" className="btn btn-success" onClick={capturePhoto}>
          Tomar Foto
        </button>
      </div>

      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

      {photo && (
        <div className="text-center mt-3">
          <h5>Vista previa:</h5>
          <img src={URL.createObjectURL(photo)} alt="Captura de la cámara" style={{ width: '320px', height: '240px' }} />
        </div>
      )}

      {/* Botón de envío personalizado */}
      <div className="text-center mt-4">
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
          Enviar
        </button>
      </div>
    </div>
  );
};

export default App;
